const {
  BI_SOURCE,
  BI_ENDPOINT: BI_VIEWER_ENDPOIINT_PREVIEW,
  BI_VIEWER_ENDPOINT: BI_VIEWER_ENDPOINT_LIVE,
  BI_ERROR_ENDPOINT
} = require('@wix/dbsm-common/src/bi/constants')
const { DATA_BINDING } = require('@wix/app-definition-ids')
const { getAppUrl } = require('@wix/dbsm-common/src/worker/getUrl')
const getAppVersion = require('@wix/dbsm-common/src/getAppVersion')
const { isPreview } = require('../../helpers/viewMode')

const APP_NAME = 'dbsm-viewer-app'

const biDefaults = {
  src: BI_SOURCE,
  ver: getAppVersion(getAppUrl(APP_NAME)),
  app_name: APP_NAME,
  app_id: DATA_BINDING
}

const getEndpointByViewMode = viewMode =>
  isPreview(viewMode) ? BI_VIEWER_ENDPOIINT_PREVIEW : BI_VIEWER_ENDPOINT_LIVE

const setupBiLogger = (viewMode, defaults, biLoggerFactory) =>
  biLoggerFactory()
    .updateDefaults({
      ...biDefaults,
      ...defaults
    })
    .logger({ endpoint: getEndpointByViewMode(viewMode) })

const setupErrorBiLogger = biLoggerFactory =>
  biLoggerFactory()
    .updateDefaults(Object.assign({}, biDefaults, { evid: 10 }))
    .logger({ endpoint: BI_ERROR_ENDPOINT })

module.exports.setupBiLogger = setupBiLogger
module.exports.setupErrorBiLogger = setupErrorBiLogger
